/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby'
import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

interface SEOProps {
  description?: string
  lang?: 'ru'
  meta?:
    | { name: string; content: string; property?: undefined }
    | { property: string; content: string; name?: undefined }[]
  title: string
}

export function SEO({
  description = '',
  lang = 'ru',
  meta = [],
  title,
}: SEOProps): ReactElement {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={[
        {
          rel: 'alternate',
          href: site.siteMetadata.title,
          hrefLang: 'ru-ru',
        },
      ]}
      titleTemplate={title || site.siteMetadata.title}
      meta={[
        {
          name: 'title',
          content: title,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}/images/og-banner.jpg`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: '@CsFortress',
        },
        {
          name: `twitter:site`,
          content: '@CsFortress',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `${site.siteMetadata.siteUrl}/images/og-banner.jpg`,
        },
        {
          name: `twitter:url`,
          content: site.siteMetadata.siteUrl,
        },
      ].concat(meta)}
    >
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Крепость',
              item: 'https://fortress.civsoc.net',
            },
          ],
        })}
      </script>
      <script
        src='https://widget.cloudpayments.ru/bundles/cloudpayments'
        defer
        async
      />
      {/* Global site tag (gtag.js) - Google Analytics */}
      <script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG_ID}`}
        async
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', ${process.env.GATSBY_GTAG_ID});
        `}
      </script>
      {/* Yandex.Metrika counter */}
      <script type='text/javascript'>
        {`
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(${process.env.GATSBY_YMETRICA_ID}, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true
          });
        `}
      </script>
      <noscript>
        {`
          <div>
            <img
              src='https://mc.yandex.ru/watch/${process.env.GATSBY_YMETRICA_ID}'
              style='position:absolute; left:-9999px;'
              alt=''
            />
          </div>
        `}
      </noscript>
      {/* /Yandex.Metrika counter */}
    </Helmet>
  )
}
