import classNames from 'classnames'
import React, { ReactElement } from 'react'
import './paragraph.scss'

type Size = '20' | '18' | '16'

interface ParagraphProps {
  children: (string | ReactElement) | (string | ReactElement)[]
  className?: string
  size?: Size
  align?: 'left' | 'center'
  marginBottom?: Size | '0'
  color?: 'blue' | 'white' | 'blue-1'
}

export const Paragraph = ({
  children,
  className,
  size = '20',
  align = 'left',
  marginBottom = size,
  color = 'blue',
}: ParagraphProps): ReactElement<HTMLParagraphElement> => (
  <p
    className={classNames('paragraph', className, [
      `paragraph--s-${size}`,
      `paragraph--a-${align}`,
      `paragraph--mb-${marginBottom}`,
      `paragraph--c-${color}`,
    ])}
  >
    {children}
  </p>
)
