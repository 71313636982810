import classNames from 'classnames'
import React, { ReactElement, HTMLAttributes } from 'react'
import './heading.scss'

type Size = '48' | '32' | '28' | '24'

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  children: (string | ReactElement) | (string | ReactElement)[]
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  className?: string
  size?: Size
  align?: 'left' | 'center'
  lineHeight?: 'large' | 'medium'
  marginBottom?: Size | '0'
  color?: 'blue' | 'white'
}

export const Heading = ({
  children,
  tag,
  className,
  size = '48',
  lineHeight = 'large',
  marginBottom = size,
  align = 'left',
  color = 'blue',
  ...props
}: HeadingProps): ReactElement<HTMLHeadingElement> => {
  const Tag = tag || 'h1'

  return (
    <Tag
      className={classNames('heading', className, [
        `heading--s-${size}`,
        `heading--lh-${lineHeight}`,
        `heading--mb-${marginBottom}`,
        `heading--a-${align}`,
        `heading--c-${color}`,
      ])}
      {...props}
    >
      {children}
    </Tag>
  )
}
